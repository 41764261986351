import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import About from '../containers/About';

const AboutPage = () => (
  <Layout>
    <Head
      title="뱅크샐러드 | About"
      description="개인이 중심이 되는새로운 데이터 생태계를 구축하는 회사--
뱅크샐러드의 목표와 현재까지 이루어낸 성과를 소개합니다."
      keywords={['뱅크샐러드', '레이니스트', '뱅크샐러드 회사', '기업', '회사소개', '인재상', '회사소개서', '핀테크', '스타트업', '마이데이터']}
    />
    <About />
  </Layout>
);

export default AboutPage;
