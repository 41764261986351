import React from 'react';

import {
  wrap, video, videoLayout,
} from './style.module.css';
import Info from '../../../components/Info';

import APP_PC from '../../../images/videos/app_proto.mp4';
import APP_POSTER from '../../../images/videos/app_proto_poster.jpg';

const Service = () => (
  <section className={wrap}>
    <div className="container-md">
      <div className="row">
        <div className="col-md-6">
          <Info
            subTitle="Our service"
            title="데이터 기반의 자산관리<br />플랫폼을 넘어<br />개인의 삶을 관리하는<br />뱅크샐러드"
            isFullWidth={false}
          >
            개인의 취향을 담은 초개인화 서비스를 통해
            <br />
            안정된 자산 관리와 합리적이고
            <br />
            즐거운 라이프스타일을 설계합니다.
          </Info>
        </div>
        <div className="col-md-6">
          <div className={videoLayout}>
            <video
              className={video}
              autoPlay
              muted
              loop
              playsInline
              poster={APP_POSTER}
            >
              <source
                src={APP_PC}
                type="video/mp4"
              />
              해당 브라우저는 비디오 기능을 제공하지 않습니다.
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Service;
