import React, { useEffect, useState } from 'react';

import {
  wrap, timeline, item as styledItem, itemWrap, tadaLayout, time, title, content,
} from './style.module.css';
import LottieView from '../../../components/Lottie';
import TADA from '../../../images/lottie/particles.json';

const LIST = [{
  time: '2014/08',
  title: '뱅크샐러드 웹 서비스 런칭',
  content: '6명이 의기투합, 첫 프로젝트인<br />뱅크샐러드 웹서비스 런칭',
  tada: false,
}, {
  time: '2017/08',
  title: '뱅크샐러드 리뉴얼 앱 런칭',
  content: '두번의 실패를 교훈삼아<br />가계부 기능을 강화한 3.0 런칭',
  tada: false,
}, {
  time: '2018/08',
  title: '1년만에 누적다운로드 200만 달성',
  content: 'TV광고 없이 고객들의 입소문으로 <br />앱 출시 1년만에 200만 다운로드를 달성',
  tada: false,
}, {
  time: '2019/08',
  title: 'Series C 투자 유치',
  content: 'PFM 서비스를 넘어 데이터 회사로서의<br />본질적인 가치를 입증, 투자자로부터<br />450억 규모의 Series C 투자 유치',
  tada: false,
}, {
  time: '2021/01',
  title: '마이데이터 사업 본허가 획득',
  content: '데이터 핵심 기술과 활용 역량을 갖춘<br />마이데이터 전문 기업으로 도약',
  tada: false,
}, {
  time: '2021/02',
  title: '누적다운로드 1000만 달성',
  content: '새로운 라이프스타일을 설계하는<br />범국민서비스로 거듭 성장',
  tada: true,
}, {
  time: '2022/06',
  title: 'Series D 투자 유치',
  content: '당초 목표치였던 1000억원을 넘어선 <br />1350억원 규모의 투자 유치',
  tada: false,
}, {
  time: '2022/11',
  title: '누적 DTC 검사자 수 20만 돌파',
  content: '런칭 1년 만에 금융 데이터를 넘어 <br />건강 데이터까지 마이데이터 서비스 범위 확장 ',
  tada: false,
}, {
  time: '2023/05',
  title: '미생물검사 서비스 출시',
  content: '',
  tada: false,
}, {
  time: '2023/07',
  title: '‘보험상품비교·추천 플랫폼’ 혁신금융서비스 지정',
  content: '',
  tada: false,
},
];

const Timeline = () => {
  const [isPlay, setIsPlay] = useState(false);
  useEffect(() => {
    const actionEvent = ({ detail }) => {
      if (detail.target.id === 'tada') {
        setIsPlay(true);
      }
    };

    window.addEventListener('sal:in', actionEvent);
    return () => window.removeEventListener('sal:in', actionEvent);
  }, []);

  return (
    <section className={wrap}>
      <div className="container-md">
        <ul className={timeline}>
          {
            LIST.map((item) => (
              <li
                key={item.time}
                className={styledItem}
              >
                <div className={itemWrap}>
                  {
                    item.tada && (
                      <div
                        id="tada"
                        className={tadaLayout}
                        data-sal="fade"
                        data-sal-duration="0"
                        data-sal-delay="0"
                        data-sal-easing="ease"
                      >
                        <LottieView
                          loop
                          autoplay={false}
                          lottieId="tada"
                          isPlay={isPlay}
                          animationData={TADA}
                        />
                      </div>
                    )
                  }
                  <time
                    className={time}
                    data-sal="slide-up"
                    data-sal-delay="0"
                    data-sal-easing="ease"
                  >
                    {item.time}
                  </time>
                  <h1
                    className={title}
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                  >
                    {item.title}
                  </h1>
                  <p
                    className={content}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                  />
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </section>
  );
};

export default Timeline;
