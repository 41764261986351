import React, { useEffect, useState } from 'react';

import Lottie from 'lottie-web';
import { wrap } from './style.module.css';

const LottieView = ({
  animationData,
  loop,
  autoplay,
  lottieId,
  isPlay = false,
}) => {
  const [lottie, setLottie] = useState(() => {});

  useEffect(() => {
    const component = document.getElementById(lottieId);
    const currentLottie = Lottie.loadAnimation({
      wrapper: component,
      animType: 'svg',
      loop,
      autoplay,
      animationData,
    });

    setLottie(currentLottie);
  }, []);

  useEffect(() => {
    if (isPlay) {
      lottie.play();
    }
  }, [isPlay]);

  return (
    <div
      id={lottieId}
      className={wrap}
    />
  );
};

export default LottieView;
