import React from 'react';

import classNames from 'classnames';
import {
  wrap, infoWrap, itemWrap, index as styledIndex, itemContents, itemTitle,
} from './style.module.css';
import Info from '../../../components/Info';

const Mission = () => (
  <section className={wrap}>
    <div className="container-md">
      <Info
        subTitle="Our mission"
        title="Empowering people with data<br />to make a better life"
        leftSectionClassNameOnFullWidth="col-md-7"
        rightSectionClassNameOnFullWidth="col-md-5"
        isFullWidth
      >
        뱅크샐러드는 데이터를 기반으로
        <br />
        불균등한 정보의 비대칭을 해결하고
        <br />
        누구나 똑똑해지는 세상을 꿈꿉니다.
      </Info>
      <div className={classNames('row', infoWrap)}>
        <article
          className={classNames(itemWrap, 'col-sm-6', 'col-md-6', 'col-lg-4')}
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="600"
          data-sal-easing="ease"
        >
          <p className={styledIndex}>
            1
          </p>
          <h1 className={itemTitle}>
            우리는 광범위한
            <br className="onlyPC" />
            { ' 데이터를 수집하고' }
          </h1>
          <p className={itemContents}>
            200여개의 금융기관의 10,000여개 데이터 수집
          </p>
          <p className={itemContents}>
            금융데이터를 넘어 건강/통신/자동차 등 생활과 밀접한 데이터로 확장
          </p>
        </article>
        <article
          className={classNames(itemWrap, 'col-sm-6', 'col-md-6', 'col-lg-4')}
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-duration="600"
          data-sal-easing="ease"
        >
          <p className={styledIndex}>
            2
          </p>
          <h1 className={itemTitle}>
            수집한 데이터를
            <br className="onlyPC" />
            { ' 활용하여' }
          </h1>
          <p className={itemContents}>
            개인별 소비데이터와 30,000여개의 달하는 카드 혜택 데이터 분석
          </p>
          <p className={itemContents}>
            고객 건강검진데이터에 기반한 고위험 질병군 파악
          </p>
          <p className={itemContents}>
            이종 데이터 결합을 통한 개인의 리스크 대비 제안
          </p>
        </article>
        <article
          className={classNames(itemWrap, 'col-sm-6', 'col-md-6', 'col-lg-4')}
          data-sal="slide-up"
          data-sal-delay="600"
          data-sal-duration="600"
          data-sal-easing="ease"
        >
          <p className={styledIndex}>
            3
          </p>
          <h1 className={itemTitle}>
            고객을 위한
            <br className="onlyPC" />
            { ' Intelligence를 만듭니다' }
          </h1>
          <p className={itemContents}>
            개인 실소비 패턴에 따른 혜택 기반 카드 추천
          </p>
          <p className={itemContents}>
            맞춤형 보험 추천 Intelligence 개발
          </p>
          <p className={itemContents}>
            최적의 자산관리 서비스 제공
          </p>
        </article>
      </div>
    </div>
  </section>
);

export default Mission;
