import React from 'react';

import Mission from './Mission';
import Hero from '../../components/Hero';
import Service from './Service';
import Timeline from './Timeline';
import IMG_HERO from '../../images/about/hero.png';

const About = ({ ...props }) => (
  <div
    {...props}
  >
    <Hero
      subTitle="Who we are"
      title="우리는 마이데이터를 활용하여<br />세상에 없던 초개인화 혁신 서비스를 만듭니다."
      cover={IMG_HERO}
      mCover={IMG_HERO}
    />
    <Mission />
    <Service />
    <Timeline />
  </div>
);

export default About;
